<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="card-header border-0">
			<h3 class="card-title font-weight-bolder text-dark">{{ name }} - Daily Usage</h3>

			<div class="col-3 d-flex align-items-center justify-content-between">
				<div>
					<b-form-radio-group
						id="btn-radios-1"
						v-model="daysSelected"
						:options="buttons"
						name="radios-btn-default"
						@input="changeDays"
						buttons
						size="sm"
						button-variant="outline-primary"
						class="custom-radio-group"
					></b-form-radio-group>
				</div>
				<b-tooltip target="csvFile" placement="top" triggers="hover">
					Download CSV File
				</b-tooltip>
				<div id="csvFile">
					
					<a @click="generateCSV" style="cursor: pointer;">
						<i class="fas fa-download"></i>
						CSV
					</a>
				</div>
			</div>
		</div>
		<div class="card-body pt-2">
			<ITCSpinner :loaded="loaded">
				<div class="row">
					<div class="col-md-9">
						<apexchart type="bar" :height="height" :options="options" :series="series" />
					</div>
					<div class="col-md-3">
						<div class="row" :style="{ height: height + 'px', overflowY: 'scroll' }">
							<table class="table table-striped table-hover fixedTableHead">
								<thead>
									<th>Date</th>
									<th>Mobile Priority</th>
									<th v-if="standard_total!=0">Mobile</th>
								</thead>
								<tbody v-if="standard_total!=0">
									<tr v-for="(d, i) in data" v-bind:key="i">
										<td>{{ d.date }}</td>
										<td>{{ d.high }} GB</td>
										<td>{{ d.standard }} GB</td>
									</tr>
								</tbody>
								<tbody v-else>
									<tr v-for="(d, i) in data" v-bind:key="i">
										<td>{{ d.date }}</td>
										<td>{{ d.high }} GB</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="row">
							<b-tooltip target="highTotal" placement="top" triggers="hover">
								Mobile Priority Total
							</b-tooltip>
							<div v-if="standard_total!=0" id="highTotal" class="col-md-6 text-left h4">Mobile Priority: {{ high_total }}GB</div>
							<!-- <div v-else class="col-md-6 text-left h4">Data Total: {{ high_total }} GB</div> -->
							<b-tooltip target="standardTotal" placement="top" triggers="hover">
								Mobile Total
							</b-tooltip>
							<div v-if="standard_total!=0" id="standardTotal" class="col-md-6 text-right h4">Mobile: {{ standard_total }}GB</div>
						</div>
						<div class="row">
							<div class="col-md-12 text-center h3">Total: {{ total_data }} GB</div>
						</div>
					</div>
				</div>
			</ITCSpinner>
		</div>
	</div>
</template>

<script>
import { downloadCSV } from '@/helpers';

export default {
	name: 'StarlinkDailyGraph',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	props: {
        siteid: { Type: Number, required: true},
        name: { Type: String, required: true },
		id: { type: Number, required: true },
		properties: { type: Object, require: false },
        device: { type: Object, require: false },
        widgetId: { type: Number, required: true}
	},
	data() {
		return {
			buttons: [
				{
					text: 'Last Month',
					value: 'lastMonth',
				},
				{ text: 'This Month', value: 'thisMonth' },
				{ text: 'Last 30 days', value: 'last30' },
			],
			csv: '',
			daysSelected: '',
			loaded: false,
			data: [],
			intstr: '',
			height: 400,
			options: null,
			series: [],
			high_total: 0,
			standard_total: 0,
			total_data: 0,
            resData: []
		};
	},
	methods: {
		processData(resData) {
            var dates = [];
			this.data = [];

			var inn = [];
			var out = [];
			this.high_total = 0;
			this.standard_total = 0;
			this.total_data = 0;
			resData.forEach((dayObj) => {
                let date = dayObj['timestamp'].split('T')[0];
                dates.push(date);
				this.data.push({ 'date': date, 'high': dayObj['highPriorityUsedGigaBytes'], 'standard': dayObj['standardPriorityUsedGigaBytes'] });
				this.high_total += dayObj['highPriorityUsedGigaBytes'];
				this.standard_total += dayObj['standardPriorityUsedGigaBytes'];
				inn.push(dayObj['highPriorityUsedGigaBytes']);
				out.push(dayObj['standardPriorityUsedGigaBytes']);
			});
			this.total_data = (this.high_total + this.standard_total).toFixed(2);
			this.high_total = this.high_total.toFixed(2);
			this.standard_total = this.standard_total.toFixed(2);

			//legend labels
			if(this.standard_total!=0) {
				this.series = [
					{ name: 'Mobile Priority', data: inn },
					{ name: 'Mobile', data: out },
				];
			} else {
				this.series = [
					{ name: 'Mobile Priority', data: inn },
				];
			}

			
			this.options = {
				series: [
					{ name: 'Mobile Priority', data: inn },
					{ name: 'Mobile', data: out },
				],
				chart: {
					type: 'bar',
					height: 350,
					stacked: true,
					toolbar: {
						show: false,
					},
				},
				plotOptions: {
					bar: {
						borderRadius: 8,
						horizontal: false,
					},
				},
				stroke: {
					width: 1,
					colors: ['#fff'],
				},
				colors: ['#133880', '#7db6e9'],
				dataLabels: {
					enabled: false,
				},
				xaxis: {
					categories: dates,
					labels: {
						rotate: -45,
					},
				},
				yaxis: {
					title: {
						text: undefined,
					},
					labels: {
						formatter: function (val) {
                            return val + ' GB';
							// return KMG(val) + 'B';
						},
					},
				},
				tooltip: {
					y: {
						formatter: function (val) {
                            return val + ' GB';
							// return KMG(val) + 'B';
						},
					},
				},
				fill: {
					opacity: 1,
				},
				legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40,
				},
			};
		},
		generateCSV() {
			let columns = [];
			let csvData = {};
			const keysToRemove = ['timestamp', 'serviceLineNumber', 'totalUsedGigaBytesThisMonth'];
			const keysArray = Object.keys(this.resData[0]).filter(key => !keysToRemove.includes(key));
				keysArray.forEach((col) => {
                    let label = col;
                    if(col.match(/(highPriorityUsedGigaBytes)/) !== null) {
                        label = 'Mobile Priority Used GB';
                    } else
                    if(col.match(/(standardPriorityUsedGigaBytes)/) !== null) {
                        label = 'Mobile Used GB';
                    } else
                    if(col.match(/(totalUsedGigaBytes)/) !== null) {
                        label = 'Total Used GB';
                    } 
					columns.push(label);
					this.resData.forEach((row) => {
						if (csvData[row['timestamp']] == undefined) csvData[row['timestamp']] = {};
						if (label === 'MODCOD') {
							if (MODCODS[row[1]] !== undefined) {
								csvData[row[0]][label] = MODCODS[row[1]].name;
							}
						} else {
							csvData[row['timestamp']][label] = row[col];
						}
					});
				});

			let csv = 'Timestamp,' + columns.join(',') + '\n';
			for (let timestamp in csvData) {
				let timestring = new Date(timestamp).toISOString().split('T');
				csv += timestring[0] + ' ' + timestring[1].substring(0, 8) + ',';
				columns.forEach((col) => {
					csv +=
						csvData[timestamp][col] !== undefined && csvData[timestamp][col] !== null
							? csvData[timestamp][col] + ','
							: ',';
				});
				csv = csv.substring(0, csv.length - 1);
				csv += '\n';
			}
			return downloadCSV(
				csv,
				this.name + ` - Starlink ${this.graphName} - ` + new Date().toISOString().split('T')[0] + '.csv'
			);
		},
		changeDays() {
			this.loaded = false;
			this.load(this.daysSelected)
		},
		load(dateRange) {
			this.loaded = false;

			this.$http.get(`site/${this.siteid}/starlink/dailyusage/${this.device.Source.serviceLineNumber}?daterange=${dateRange}`)
				.then((res) => {
                    this.resData = res.data.results;
					this.processData(res.data.results);
					this.loaded = true;
				})
                .catch(err => {
                    this.loaded = true;
                    this.errors.push(err);
                    console.log("Error(Starlink API Data): ", this.errors);                                                                            
                });
		},
	},
    created() {
        // console.log("*** Created ***");
    },
	mounted() {
        // console.log('mounted **starlink daily** ')
        if(!this.device.Source.serviceLineNumber) {
            const starlink = JSON.parse(this.device.Source);
            this.device.Source = starlink;
        } 
		this.load('thisMonth');
	},
};
</script>

<style scoped>
.fixed-table-head {
	overflow-y: scroll;
}
table.fixedTableHead thead th {
	position: sticky;
	top: 0;
	background: #fff;
	box-shadow: 0 4px 10px -2px #eef1f5;
}
/* Custom CSS for radio group */
.custom-radio-group {
  /* Prevent text wrapping */
  white-space: nowrap;
}

.custom-radio-group .btn {
    padding: 0.12rem 0.12rem;
    font-size: 0.075rem; /* Adjust font size as needed */
}
</style>
